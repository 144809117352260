.rmc-steps-item-process {
  .rmc-steps-item-icon {
    border-color: var(--primary);
    background-color: var(--primary);
    color: #fff;
  }

  .rmc-steps-item-title {
    color: var(--dark);
  }

  .rmc-steps-item-title::after {
    background-color: var(--gray);
  }
}

.rmc-steps-item-wait {
  .rmc-steps-item-icon {
    border-color: var(--gray);
    background-color: var(--gray);
    color: #fff;
  }

  .rmc-steps-item-title::after {
    background-color: var(--gray);
  }
}

.rmc-steps-item-warning {
  .rmc-steps-item-icon {
    border-color: var(--warning);
    background-color: var(--warning);
    color: #fff;

    .rmc-steps-icon {
      top: 3px;
    }
  }

  .rmc-steps-item-title::after {
    background-color: var(--gray);
  }
}

.rmc-steps-item-finish {
  .rmc-steps-item-icon {
    border-color: var(--success);
    background-color: var(--success);
    color: #fff;

    .rmc-steps-icon {
      top: 3px;
    }
  }

  .rmc-steps-item-title::after {
    background-color: var(--gray);
  }
}
